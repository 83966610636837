























import Vue from "vue";
var json = require("@/static/content.json");
// @ is an alias to /src

export default Vue.extend({
  name: "homePage",
  components: {},
  data() {
    return {
      json: {},
    };
  },
  mounted() {
    this.json = json;
  },
});

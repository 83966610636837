














































import Vue from "vue";
export default Vue.extend({
  data() {
    return {
      isActivated: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isActivated = !this.isActivated;
    },
    scrollToSection(section: string) {
      let sectionElement = document.getElementById(section);
      if (sectionElement != null) {
        sectionElement.scrollIntoView({ block: "start", behavior: "smooth" });
        this.isActivated = !this.isActivated;
      } else {
        // eslint-disable-next-line
        console.log(
          "The element associated with the menu item is null / could not be found."
        );
      }
    },
  },
});

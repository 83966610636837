
























































import Vue from "vue";
export default Vue.extend({
  data() {
    return {
      position: [
        "software engineering",
        "product management",
        "social entrepreneurship",
      ],
    };
  },
  methods: {
    scrollToExperience() {
      let sectionElement = document.getElementById("experience");
      if (sectionElement != null) {
        sectionElement.scrollIntoView({ block: "start", behavior: "smooth" });
      } else {
        // eslint-disable-next-line
        console.log(
          "The element associated with the menu item is null / could not be found."
        );
      }
    },
    goToResume() {
      window.open(
        "https://drive.google.com/open?id=1znD9D40aV2y3--KMBDNguyxXOTcXZnXw",
        "_blank"
      );
    },
    changePositionText() {
      // eslint-disable-next-line
      const firstElement = this.position.shift()!;
      this.position.push(firstElement);
    },
  },
  mounted() {
    window.setInterval(() => {
      this.changePositionText();
    }, 1750);
  },
});
